@import "./styles/about";
@import "./styles/projects";
@import "./styles/contact";
@import "./styles/layout";
@import "./styles/home";
@import "./styles/navbar";
@import "./styles/research";
@import "./styles/hobbies";
@import "./styles/title";
@import "./styles/resume";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
body::-webkit-scrollbar-thumb {
  background-color: #04fcdb;
  width: 1px;
  height: 1px;
}
body::-webkit-scrollbar-track {
  background-color: #777;
}

body {
  color: #a4acc4;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.2rem;
}
span {
  color: #037fff;
}

@media screen and (max-width: 411px) {
  html {
    font-size: 70%;
  }
}
@media screen and (max-width: 700px) {
  html {
    font-size: 90%;
  }
}
@media screen and (max-width: 480px) {
  h2 {
    font-size: 70%;
  }
  h3 {
    font-size: 70%;
  }
}
