.navbar-container {
  height: 100vh;
  .navbar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .navbar-profile {
      margin-top: 4rem;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid silver;
      img {
        width: 70%;
        border-radius: 100%;
        border: 5px solid #2e344e;
        margin-bottom: 1rem;
      }
    }

    .navbar-items {
      width: 100%;
      .navbar-item {
        list-style: none;
        font-weight: bold;
        text-align: center;
        margin-top: 1rem;

        a {
          text-decoration: none;
          font-size: 1.5rem;
          color: inherit;
          display: block;
          padding: 0.6rem 0;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0.4rem;
            height: 100%;
            background-color: #0381ff33;
            transform-origin: bottom;
            transform: scale(0);
            overflow-x: hidden;
            transition: transform 0.4s,
              0.2s width 0.2s cubic-bezier(1, -0.16, 0.6, 1.35);
          }

          &:hover::before {
            width: 100%;
            transform: scale(1);
          }
        }
      }
    }

    .footer {
      width: 100%;
      border-top: 1px solid silver;
      p {
        text-align: center;
        padding: 1rem 0;
      }
    }
  }
}

.active {
  background-color: #037fff;
}
