.image-section {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;
  .img-container {
    width: 100%;
    height: 50vh;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .about-info {
    margin-left: 2rem;
    font-weight: bold;
    h4 {
      font-size: 2.5rem;
    }
    .about-text {
      padding: 1rem 0;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    .about-info {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

.about-details {
  display: flex;
  p {
    padding: 0.3rem 0;
  }
  .about-values {
    margin-left: 2.3rem;
  }
}

.about-button {
  padding: 1rem 2rem;
  background-color: #037fff;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 2px;
  margin-top: 1rem;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0.3rem;
    left: 0;
    bottom: 0;
    background-color: #a4acc4;
    transition: all 0.4s ease-in-out;
  }
  &:hover::after {
    width: 100%;
  }
}
