html {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: scroll;
}

#stars {
  width: 1px;
  height: 1px;

  box-shadow: 785px 1753px #fff, 801px 356px #fff, 1312px 1772px #fff,
    609px 166px #fff, 634px 941px #fff, 1827px 1145px #fff, 1982px 1254px #fff,
    1030px 3px #fff, 1705px 770px #fff, 1270px 515px #fff, 375px 1137px #fff,
    568px 1672px #fff, 400px 775px #fff, 407px 1492px #fff, 1282px 1833px #fff,
    907px 1561px #fff, 937px 1681px #fff, 826px 1474px #fff, 1982px 563px #fff,
    1326px 727px #fff, 838px 1477px #fff, 546px 939px #fff, 689px 1710px #fff,
    1176px 1662px #fff, 1357px 1990px #fff, 1326px 496px #fff, 1683px 32px #fff,
    1722px 1783px #fff, 214px 309px #fff, 1651px 1745px #fff, 1465px 790px #fff,
    83px 485px #fff, 868px 858px #fff, 1348px 211px #fff, 1193px 262px #fff,
    1898px 1043px #fff, 1853px 128px #fff, 182px 1094px #fff, 1900px 1291px #fff,
    1171px 1590px #fff, 470px 1138px #fff, 379px 323px #fff, 1041px 1709px #fff,
    1561px 192px #fff, 313px 953px #fff, 1639px 597px #fff, 890px 641px #fff,
    34px 1908px #fff, 1152px 1166px #fff, 356px 104px #fff, 324px 1982px #fff,
    1801px 1393px #fff, 1585px 246px #fff, 1880px 1330px #fff, 944px 1696px #fff,
    1915px 1771px #fff, 34px 45px #fff, 1366px 1820px #fff, 1635px 1992px #fff,
    1127px 1084px #fff, 1562px 285px #fff, 161px 886px #fff, 1880px 1965px #fff,
    1601px 534px #fff, 204px 1728px #fff, 613px 238px #fff, 1390px 1423px #fff,
    1919px 1257px #fff, 915px 1562px #fff, 541px 590px #fff, 805px 160px #fff,
    700px 934px #fff, 1062px 879px #fff, 133px 1676px #fff, 796px 560px #fff,
    603px 207px #fff, 1001px 457px #fff, 1100px 350px #fff, 618px 270px #fff,
    1162px 743px #fff, 1879px 1326px #fff, 1100px 1310px #fff, 1932px 516px #fff,
    265px 1681px #fff, 1800px 3px #fff, 714px 201px #fff, 1923px 1909px #fff,
    770px 773px #fff, 612px 1637px #fff, 1527px 1829px #fff, 66px 1372px #fff,
    569px 613px #fff, 358px 1482px #fff, 893px 642px #fff, 1161px 422px #fff,
    1507px 1339px #fff, 1572px 429px #fff, 160px 513px #fff, 154px 1231px #fff,
    501px 1737px #fff, 258px 524px #fff, 1202px 1488px #fff, 578px 1719px #fff,
    249px 214px #fff, 1404px 1429px #fff, 1502px 715px #fff, 1699px 832px #fff,
    361px 1879px #fff, 291px 969px #fff, 310px 133px #fff, 1977px 1799px #fff,
    403px 549px #fff, 169px 1204px #fff, 1115px 1227px #fff, 1763px 915px #fff,
    747px 398px #fff, 1140px 263px #fff, 1275px 1591px #fff, 757px 1637px #fff,
    76px 842px #fff, 267px 1300px #fff, 571px 1346px #fff, 259px 1349px #fff,
    1993px 218px #fff, 849px 736px #fff, 791px 470px #fff, 615px 1529px #fff,
    1763px 15px #fff, 1614px 1313px #fff, 1395px 1496px #fff, 1795px 1536px #fff,
    1051px 541px #fff, 756px 14px #fff, 1526px 868px #fff, 132px 1716px #fff,
    1455px 1248px #fff, 1120px 1187px #fff, 710px 1098px #fff, 1681px 361px #fff,
    1438px 1107px #fff, 640px 1522px #fff, 894px 1353px #fff, 303px 865px #fff,
    617px 448px #fff, 1508px 201px #fff, 283px 591px #fff, 519px 1699px #fff,
    787px 269px #fff, 1090px 451px #fff, 1025px 87px #fff, 804px 930px #fff,
    1564px 1021px #fff, 510px 1050px #fff, 1438px 1662px #fff, 1784px 81px #fff,
    228px 587px #fff, 1813px 1310px #fff, 530px 596px #fff, 1040px 1070px #fff,
    892px 1291px #fff, 1739px 1241px #fff, 1441px 281px #fff, 20px 1239px #fff,
    1212px 678px #fff, 297px 1244px #fff, 74px 913px #fff, 209px 660px #fff,
    1441px 1754px #fff, 561px 431px #fff, 1703px 717px #fff, 1901px 1946px #fff,
    891px 440px #fff, 1567px 314px #fff, 440px 895px #fff, 1205px 458px #fff,
    1050px 1742px #fff, 223px 1607px #fff, 704px 1913px #fff, 1919px 1574px #fff,
    1398px 1175px #fff, 1510px 450px #fff, 763px 1576px #fff, 1945px 961px #fff,
    1932px 851px #fff, 1791px 294px #fff, 686px 1600px #fff, 1792px 1184px #fff,
    1001px 849px #fff, 1290px 931px #fff, 357px 991px #fff, 1000px 301px #fff,
    1900px 1695px #fff, 40px 102px #fff, 546px 1905px #fff, 894px 188px #fff,
    1335px 308px #fff, 1320px 1788px #fff, 1809px 924px #fff, 752px 161px #fff,
    835px 134px #fff, 1551px 1001px #fff, 412px 734px #fff, 1303px 1871px #fff,
    1798px 1898px #fff, 1311px 1509px #fff, 243px 893px #fff, 811px 498px #fff,
    591px 6px #fff, 297px 1172px #fff, 492px 929px #fff, 1919px 918px #fff,
    1449px 822px #fff, 882px 888px #fff, 1168px 916px #fff, 870px 863px #fff,
    146px 852px #fff, 1694px 896px #fff, 712px 1954px #fff, 1149px 148px #fff,
    993px 1976px #fff, 1778px 1620px #fff, 1774px 1518px #fff,
    1458px 1679px #fff, 804px 591px #fff, 305px 412px #fff, 1075px 678px #fff,
    1378px 804px #fff, 1347px 1816px #fff, 162px 438px #fff, 1838px 1522px #fff,
    844px 407px #fff, 1866px 334px #fff, 1995px 190px #fff, 357px 1800px #fff,
    117px 418px #fff, 49px 841px #fff, 1637px 1813px #fff, 1268px 104px #fff,
    613px 1547px #fff, 1682px 824px #fff, 1073px 1305px #fff, 1692px 316px #fff,
    1580px 1798px #fff, 1735px 1552px #fff, 907px 1082px #fff, 972px 1261px #fff,
    1832px 1710px #fff, 1959px 1144px #fff, 1561px 255px #fff, 1914px 52px #fff,
    1314px 1050px #fff, 101px 483px #fff, 1158px 1924px #fff, 1941px 35px #fff,
    70px 738px #fff, 70px 1792px #fff, 1922px 241px #fff, 870px 1572px #fff,
    1753px 685px #fff, 315px 952px #fff, 1820px 676px #fff, 130px 741px #fff,
    310px 1835px #fff, 654px 195px #fff, 703px 1479px #fff, 530px 1467px #fff,
    1619px 1461px #fff, 1016px 1200px #fff, 1859px 936px #fff, 814px 212px #fff,
    306px 10px #fff, 1447px 102px #fff, 1655px 935px #fff, 1782px 454px #fff,
    1785px 146px #fff, 1521px 376px #fff, 2px 1038px #fff, 175px 734px #fff,
    660px 511px #fff, 353px 920px #fff, 1385px 217px #fff, 711px 1993px #fff,
    119px 699px #fff, 1447px 690px #fff, 1520px 780px #fff, 1078px 1232px #fff,
    1733px 743px #fff, 735px 658px #fff, 1340px 223px #fff, 1445px 288px #fff,
    45px 842px #fff, 1130px 1530px #fff, 1145px 1127px #fff, 1602px 670px #fff,
    966px 1808px #fff, 1284px 277px #fff, 687px 1190px #fff, 576px 570px #fff,
    1736px 579px #fff, 306px 41px #fff, 656px 135px #fff, 674px 1361px #fff,
    744px 854px #fff, 1908px 1px #fff, 1816px 1452px #fff, 467px 1845px #fff,
    194px 376px #fff, 393px 246px #fff, 1520px 1275px #fff, 14px 509px #fff,
    679px 1998px #fff, 1786px 1289px #fff, 287px 892px #fff, 1196px 550px #fff,
    714px 1164px #fff, 1421px 1850px #fff, 1273px 1066px #fff, 1590px 370px #fff,
    1823px 1925px #fff, 1097px 574px #fff, 889px 1333px #fff, 986px 1232px #fff,
    783px 467px #fff, 213px 751px #fff, 667px 1633px #fff, 1496px 412px #fff,
    1193px 1747px #fff, 1681px 1909px #fff, 653px 347px #fff, 544px 1245px #fff,
    1449px 628px #fff, 1244px 1484px #fff, 455px 1739px #fff, 344px 324px #fff,
    245px 303px #fff, 1710px 1216px #fff, 1896px 797px #fff, 1909px 1856px #fff,
    1044px 288px #fff, 1768px 741px #fff, 511px 1182px #fff, 357px 1066px #fff,
    311px 1796px #fff, 894px 494px #fff, 1461px 1933px #fff, 1453px 1480px #fff,
    434px 59px #fff, 1340px 371px #fff, 1885px 882px #fff, 663px 1413px #fff,
    1348px 65px #fff, 586px 1918px #fff, 1062px 471px #fff, 285px 738px #fff,
    1786px 1185px #fff, 1440px 1524px #fff, 591px 1667px #fff,
    1830px 1863px #fff, 950px 1935px #fff, 1925px 1722px #fff, 711px 1077px #fff,
    1558px 1683px #fff, 344px 11px #fff, 658px 1431px #fff, 654px 1178px #fff,
    1564px 988px #fff, 1787px 1419px #fff, 870px 1304px #fff, 1182px 706px #fff,
    371px 978px #fff, 721px 191px #fff, 924px 910px #fff, 111px 905px #fff,
    874px 1992px #fff, 795px 1405px #fff, 872px 1616px #fff, 564px 377px #fff,
    1246px 1055px #fff, 1319px 1940px #fff, 1270px 1084px #fff,
    1511px 324px #fff, 271px 14px #fff, 315px 1297px #fff, 1144px 1126px #fff,
    1064px 800px #fff, 1901px 1440px #fff, 577px 1238px #fff, 914px 1406px #fff,
    507px 1645px #fff, 400px 414px #fff, 792px 893px #fff, 1283px 1860px #fff,
    1239px 1808px #fff, 1006px 956px #fff, 101px 306px #fff, 1326px 1905px #fff,
    1858px 754px #fff, 608px 349px #fff, 79px 255px #fff, 426px 880px #fff,
    1020px 689px #fff, 877px 397px #fff, 780px 31px #fff, 1408px 576px #fff,
    1806px 1577px #fff, 662px 1792px #fff, 200px 1495px #fff, 1540px 572px #fff,
    87px 312px #fff, 240px 908px #fff, 660px 1589px #fff, 1431px 597px #fff,
    1560px 715px #fff, 1581px 1894px #fff, 1692px 441px #fff, 1003px 702px #fff,
    897px 1797px #fff, 263px 1583px #fff, 1974px 962px #fff, 1830px 951px #fff,
    203px 563px #fff, 1323px 1457px #fff, 503px 660px #fff, 96px 1711px #fff,
    1028px 1874px #fff, 1378px 1908px #fff, 1072px 1545px #fff,
    1565px 761px #fff, 1728px 1043px #fff, 493px 838px #fff, 693px 1268px #fff,
    565px 1576px #fff, 742px 372px #fff, 1685px 961px #fff, 1883px 1384px #fff,
    702px 641px #fff, 759px 12px #fff, 1782px 42px #fff, 13px 1616px #fff,
    875px 877px #fff, 1045px 1448px #fff, 431px 1347px #fff, 1042px 1952px #fff,
    894px 1257px #fff, 208px 1075px #fff, 2px 102px #fff, 1884px 464px #fff,
    1237px 1298px #fff, 1381px 1238px #fff, 1508px 1090px #fff,
    1411px 123px #fff, 1025px 1315px #fff, 1701px 1783px #fff,
    1720px 1979px #fff, 1304px 1950px #fff, 952px 577px #fff, 549px 799px #fff,
    991px 349px #fff, 1352px 759px #fff, 842px 247px #fff, 827px 1173px #fff,
    95px 465px #fff, 1930px 1523px #fff, 480px 250px #fff, 1678px 1811px #fff,
    1882px 1230px #fff, 10px 1792px #fff, 1841px 915px #fff, 1542px 1528px #fff,
    265px 821px #fff, 645px 853px #fff, 1970px 1514px #fff, 1936px 1728px #fff,
    846px 1425px #fff, 166px 1848px #fff, 275px 300px #fff, 1420px 1609px #fff,
    320px 546px #fff, 1162px 1979px #fff, 1445px 1350px #fff, 979px 42px #fff,
    1984px 1977px #fff, 1693px 815px #fff, 694px 416px #fff, 1276px 1142px #fff,
    1672px 1579px #fff, 1464px 1454px #fff, 789px 1422px #fff, 226px 659px #fff,
    1665px 1068px #fff, 9px 1574px #fff, 546px 863px #fff, 1804px 1757px #fff,
    1725px 891px #fff, 247px 1845px #fff, 86px 1646px #fff, 970px 1896px #fff,
    900px 447px #fff, 1587px 915px #fff, 956px 251px #fff, 191px 1618px #fff,
    102px 288px #fff, 575px 1020px #fff, 1363px 1474px #fff, 232px 171px #fff,
    140px 553px #fff, 1421px 610px #fff, 1452px 137px #fff, 1634px 1756px #fff,
    1158px 1600px #fff, 1531px 1225px #fff, 237px 1285px #fff,
    1555px 1317px #fff, 683px 236px #fff, 1358px 810px #fff, 178px 1162px #fff,
    380px 1178px #fff, 1847px 1596px #fff, 216px 149px #fff, 504px 112px #fff,
    1232px 1094px #fff, 136px 598px #fff, 1679px 562px #fff, 289px 921px #fff,
    1731px 570px #fff, 362px 1432px #fff, 506px 1642px #fff, 1671px 642px #fff,
    1007px 1243px #fff, 203px 416px #fff, 803px 896px #fff, 1973px 1819px #fff,
    612px 198px #fff, 863px 1864px #fff, 975px 673px #fff, 116px 948px #fff,
    1635px 1921px #fff, 1031px 115px #fff, 1616px 1525px #fff, 500px 519px #fff,
    549px 425px #fff, 1746px 1505px #fff, 1669px 1058px #fff, 891px 1953px #fff,
    946px 647px #fff, 1021px 432px #fff, 991px 841px #fff, 383px 1773px #fff,
    1947px 1249px #fff, 1526px 1055px #fff, 1072px 973px #fff, 751px 1066px #fff,
    816px 1289px #fff, 1362px 956px #fff, 889px 629px #fff, 390px 89px #fff,
    1523px 725px #fff, 423px 43px #fff, 794px 81px #fff, 706px 1552px #fff,
    637px 245px #fff, 1847px 43px #fff, 965px 1249px #fff, 1653px 1496px #fff,
    1330px 1901px #fff, 638px 824px #fff, 891px 612px #fff, 217px 677px #fff,
    145px 1272px #fff, 1553px 834px #fff, 1058px 1959px #fff, 1062px 995px #fff,
    420px 531px #fff, 1463px 666px #fff, 1975px 1971px #fff, 1434px 872px #fff,
    110px 1445px #fff, 1917px 1659px #fff, 1462px 1427px #fff, 10px 322px #fff,
    1935px 557px #fff, 1484px 516px #fff, 632px 1593px #fff, 1085px 666px #fff,
    1003px 1039px #fff, 1432px 1377px #fff, 1703px 88px #fff, 511px 725px #fff,
    481px 1738px #fff, 1831px 1px #fff, 619px 1825px #fff, 212px 1208px #fff,
    1154px 67px #fff, 1881px 1345px #fff, 1852px 744px #fff, 1563px 945px #fff,
    766px 1746px #fff, 209px 1601px #fff, 990px 1959px #fff, 1296px 707px #fff,
    1888px 939px #fff, 327px 1447px #fff, 194px 1689px #fff, 1289px 1906px #fff,
    305px 887px #fff, 1430px 355px #fff, 1450px 1563px #fff, 279px 1232px #fff,
    322px 457px #fff, 1508px 908px #fff, 1169px 1977px #fff, 248px 1157px #fff,
    73px 1030px #fff, 868px 1109px #fff, 1787px 1231px #fff, 534px 1867px #fff,
    1756px 1068px #fff, 121px 1917px #fff, 191px 633px #fff, 295px 200px #fff,
    750px 882px #fff, 1133px 807px #fff, 562px 548px #fff, 634px 579px #fff,
    1413px 1453px #fff, 109px 147px #fff, 1858px 44px #fff, 1145px 94px #fff,
    247px 693px #fff, 147px 100px #fff, 823px 372px #fff, 1742px 457px #fff,
    165px 926px #fff, 5px 1810px #fff, 1783px 345px #fff, 74px 1390px #fff,
    778px 1419px #fff, 846px 297px #fff, 1918px 1360px #fff, 166px 249px #fff,
    1962px 796px #fff, 1159px 82px #fff, 975px 1765px #fff, 1481px 470px #fff,
    135px 1723px #fff, 1319px 82px #fff, 1833px 1228px #fff, 1997px 619px #fff,
    1905px 1442px #fff, 1702px 1228px #fff, 1769px 1474px #fff,
    1334px 327px #fff, 1961px 364px #fff, 667px 580px #fff, 8px 386px #fff,
    192px 879px #fff, 1277px 288px #fff, 734px 4px #fff, 429px 862px #fff,
    1494px 853px #fff, 435px 894px #fff, 1621px 1436px #fff, 899px 1480px #fff,
    963px 699px #fff, 572px 1975px #fff, 1145px 1412px #fff, 225px 1161px #fff,
    1016px 402px #fff, 1621px 616px #fff, 33px 438px #fff, 66px 488px #fff,
    1847px 515px #fff, 1869px 1752px #fff, 1943px 1990px #fff,
    1136px 1445px #fff, 1097px 1868px #fff, 1035px 655px #fff, 1170px 466px #fff,
    1512px 1253px #fff, 732px 963px #fff, 1059px 371px #fff, 1749px 930px #fff,
    1086px 1514px #fff, 4px 1250px #fff, 1559px 1420px #fff, 1649px 616px #fff,
    551px 1438px #fff, 471px 1326px #fff, 211px 1307px #fff, 558px 330px #fff,
    440px 571px #fff, 891px 959px #fff, 1458px 1604px #fff, 1852px 145px #fff,
    1832px 446px #fff, 1945px 786px #fff, 1956px 1405px #fff, 670px 682px #fff,
    936px 1496px #fff, 1163px 739px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;

  box-shadow: 785px 1753px #fff, 801px 356px #fff, 1312px 1772px #fff,
    609px 166px #fff, 634px 941px #fff, 1827px 1145px #fff, 1982px 1254px #fff,
    1030px 3px #fff, 1705px 770px #fff, 1270px 515px #fff, 375px 1137px #fff,
    568px 1672px #fff, 400px 775px #fff, 407px 1492px #fff, 1282px 1833px #fff,
    907px 1561px #fff, 937px 1681px #fff, 826px 1474px #fff, 1982px 563px #fff,
    1326px 727px #fff, 838px 1477px #fff, 546px 939px #fff, 689px 1710px #fff,
    1176px 1662px #fff, 1357px 1990px #fff, 1326px 496px #fff, 1683px 32px #fff,
    1722px 1783px #fff, 214px 309px #fff, 1651px 1745px #fff, 1465px 790px #fff,
    83px 485px #fff, 868px 858px #fff, 1348px 211px #fff, 1193px 262px #fff,
    1898px 1043px #fff, 1853px 128px #fff, 182px 1094px #fff, 1900px 1291px #fff,
    1171px 1590px #fff, 470px 1138px #fff, 379px 323px #fff, 1041px 1709px #fff,
    1561px 192px #fff, 313px 953px #fff, 1639px 597px #fff, 890px 641px #fff,
    34px 1908px #fff, 1152px 1166px #fff, 356px 104px #fff, 324px 1982px #fff,
    1801px 1393px #fff, 1585px 246px #fff, 1880px 1330px #fff, 944px 1696px #fff,
    1915px 1771px #fff, 34px 45px #fff, 1366px 1820px #fff, 1635px 1992px #fff,
    1127px 1084px #fff, 1562px 285px #fff, 161px 886px #fff, 1880px 1965px #fff,
    1601px 534px #fff, 204px 1728px #fff, 613px 238px #fff, 1390px 1423px #fff,
    1919px 1257px #fff, 915px 1562px #fff, 541px 590px #fff, 805px 160px #fff,
    700px 934px #fff, 1062px 879px #fff, 133px 1676px #fff, 796px 560px #fff,
    603px 207px #fff, 1001px 457px #fff, 1100px 350px #fff, 618px 270px #fff,
    1162px 743px #fff, 1879px 1326px #fff, 1100px 1310px #fff, 1932px 516px #fff,
    265px 1681px #fff, 1800px 3px #fff, 714px 201px #fff, 1923px 1909px #fff,
    770px 773px #fff, 612px 1637px #fff, 1527px 1829px #fff, 66px 1372px #fff,
    569px 613px #fff, 358px 1482px #fff, 893px 642px #fff, 1161px 422px #fff,
    1507px 1339px #fff, 1572px 429px #fff, 160px 513px #fff, 154px 1231px #fff,
    501px 1737px #fff, 258px 524px #fff, 1202px 1488px #fff, 578px 1719px #fff,
    249px 214px #fff, 1404px 1429px #fff, 1502px 715px #fff, 1699px 832px #fff,
    361px 1879px #fff, 291px 969px #fff, 310px 133px #fff, 1977px 1799px #fff,
    403px 549px #fff, 169px 1204px #fff, 1115px 1227px #fff, 1763px 915px #fff,
    747px 398px #fff, 1140px 263px #fff, 1275px 1591px #fff, 757px 1637px #fff,
    76px 842px #fff, 267px 1300px #fff, 571px 1346px #fff, 259px 1349px #fff,
    1993px 218px #fff, 849px 736px #fff, 791px 470px #fff, 615px 1529px #fff,
    1763px 15px #fff, 1614px 1313px #fff, 1395px 1496px #fff, 1795px 1536px #fff,
    1051px 541px #fff, 756px 14px #fff, 1526px 868px #fff, 132px 1716px #fff,
    1455px 1248px #fff, 1120px 1187px #fff, 710px 1098px #fff, 1681px 361px #fff,
    1438px 1107px #fff, 640px 1522px #fff, 894px 1353px #fff, 303px 865px #fff,
    617px 448px #fff, 1508px 201px #fff, 283px 591px #fff, 519px 1699px #fff,
    787px 269px #fff, 1090px 451px #fff, 1025px 87px #fff, 804px 930px #fff,
    1564px 1021px #fff, 510px 1050px #fff, 1438px 1662px #fff, 1784px 81px #fff,
    228px 587px #fff, 1813px 1310px #fff, 530px 596px #fff, 1040px 1070px #fff,
    892px 1291px #fff, 1739px 1241px #fff, 1441px 281px #fff, 20px 1239px #fff,
    1212px 678px #fff, 297px 1244px #fff, 74px 913px #fff, 209px 660px #fff,
    1441px 1754px #fff, 561px 431px #fff, 1703px 717px #fff, 1901px 1946px #fff,
    891px 440px #fff, 1567px 314px #fff, 440px 895px #fff, 1205px 458px #fff,
    1050px 1742px #fff, 223px 1607px #fff, 704px 1913px #fff, 1919px 1574px #fff,
    1398px 1175px #fff, 1510px 450px #fff, 763px 1576px #fff, 1945px 961px #fff,
    1932px 851px #fff, 1791px 294px #fff, 686px 1600px #fff, 1792px 1184px #fff,
    1001px 849px #fff, 1290px 931px #fff, 357px 991px #fff, 1000px 301px #fff,
    1900px 1695px #fff, 40px 102px #fff, 546px 1905px #fff, 894px 188px #fff,
    1335px 308px #fff, 1320px 1788px #fff, 1809px 924px #fff, 752px 161px #fff,
    835px 134px #fff, 1551px 1001px #fff, 412px 734px #fff, 1303px 1871px #fff,
    1798px 1898px #fff, 1311px 1509px #fff, 243px 893px #fff, 811px 498px #fff,
    591px 6px #fff, 297px 1172px #fff, 492px 929px #fff, 1919px 918px #fff,
    1449px 822px #fff, 882px 888px #fff, 1168px 916px #fff, 870px 863px #fff,
    146px 852px #fff, 1694px 896px #fff, 712px 1954px #fff, 1149px 148px #fff,
    993px 1976px #fff, 1778px 1620px #fff, 1774px 1518px #fff,
    1458px 1679px #fff, 804px 591px #fff, 305px 412px #fff, 1075px 678px #fff,
    1378px 804px #fff, 1347px 1816px #fff, 162px 438px #fff, 1838px 1522px #fff,
    844px 407px #fff, 1866px 334px #fff, 1995px 190px #fff, 357px 1800px #fff,
    117px 418px #fff, 49px 841px #fff, 1637px 1813px #fff, 1268px 104px #fff,
    613px 1547px #fff, 1682px 824px #fff, 1073px 1305px #fff, 1692px 316px #fff,
    1580px 1798px #fff, 1735px 1552px #fff, 907px 1082px #fff, 972px 1261px #fff,
    1832px 1710px #fff, 1959px 1144px #fff, 1561px 255px #fff, 1914px 52px #fff,
    1314px 1050px #fff, 101px 483px #fff, 1158px 1924px #fff, 1941px 35px #fff,
    70px 738px #fff, 70px 1792px #fff, 1922px 241px #fff, 870px 1572px #fff,
    1753px 685px #fff, 315px 952px #fff, 1820px 676px #fff, 130px 741px #fff,
    310px 1835px #fff, 654px 195px #fff, 703px 1479px #fff, 530px 1467px #fff,
    1619px 1461px #fff, 1016px 1200px #fff, 1859px 936px #fff, 814px 212px #fff,
    306px 10px #fff, 1447px 102px #fff, 1655px 935px #fff, 1782px 454px #fff,
    1785px 146px #fff, 1521px 376px #fff, 2px 1038px #fff, 175px 734px #fff,
    660px 511px #fff, 353px 920px #fff, 1385px 217px #fff, 711px 1993px #fff,
    119px 699px #fff, 1447px 690px #fff, 1520px 780px #fff, 1078px 1232px #fff,
    1733px 743px #fff, 735px 658px #fff, 1340px 223px #fff, 1445px 288px #fff,
    45px 842px #fff, 1130px 1530px #fff, 1145px 1127px #fff, 1602px 670px #fff,
    966px 1808px #fff, 1284px 277px #fff, 687px 1190px #fff, 576px 570px #fff,
    1736px 579px #fff, 306px 41px #fff, 656px 135px #fff, 674px 1361px #fff,
    744px 854px #fff, 1908px 1px #fff, 1816px 1452px #fff, 467px 1845px #fff,
    194px 376px #fff, 393px 246px #fff, 1520px 1275px #fff, 14px 509px #fff,
    679px 1998px #fff, 1786px 1289px #fff, 287px 892px #fff, 1196px 550px #fff,
    714px 1164px #fff, 1421px 1850px #fff, 1273px 1066px #fff, 1590px 370px #fff,
    1823px 1925px #fff, 1097px 574px #fff, 889px 1333px #fff, 986px 1232px #fff,
    783px 467px #fff, 213px 751px #fff, 667px 1633px #fff, 1496px 412px #fff,
    1193px 1747px #fff, 1681px 1909px #fff, 653px 347px #fff, 544px 1245px #fff,
    1449px 628px #fff, 1244px 1484px #fff, 455px 1739px #fff, 344px 324px #fff,
    245px 303px #fff, 1710px 1216px #fff, 1896px 797px #fff, 1909px 1856px #fff,
    1044px 288px #fff, 1768px 741px #fff, 511px 1182px #fff, 357px 1066px #fff,
    311px 1796px #fff, 894px 494px #fff, 1461px 1933px #fff, 1453px 1480px #fff,
    434px 59px #fff, 1340px 371px #fff, 1885px 882px #fff, 663px 1413px #fff,
    1348px 65px #fff, 586px 1918px #fff, 1062px 471px #fff, 285px 738px #fff,
    1786px 1185px #fff, 1440px 1524px #fff, 591px 1667px #fff,
    1830px 1863px #fff, 950px 1935px #fff, 1925px 1722px #fff, 711px 1077px #fff,
    1558px 1683px #fff, 344px 11px #fff, 658px 1431px #fff, 654px 1178px #fff,
    1564px 988px #fff, 1787px 1419px #fff, 870px 1304px #fff, 1182px 706px #fff,
    371px 978px #fff, 721px 191px #fff, 924px 910px #fff, 111px 905px #fff,
    874px 1992px #fff, 795px 1405px #fff, 872px 1616px #fff, 564px 377px #fff,
    1246px 1055px #fff, 1319px 1940px #fff, 1270px 1084px #fff,
    1511px 324px #fff, 271px 14px #fff, 315px 1297px #fff, 1144px 1126px #fff,
    1064px 800px #fff, 1901px 1440px #fff, 577px 1238px #fff, 914px 1406px #fff,
    507px 1645px #fff, 400px 414px #fff, 792px 893px #fff, 1283px 1860px #fff,
    1239px 1808px #fff, 1006px 956px #fff, 101px 306px #fff, 1326px 1905px #fff,
    1858px 754px #fff, 608px 349px #fff, 79px 255px #fff, 426px 880px #fff,
    1020px 689px #fff, 877px 397px #fff, 780px 31px #fff, 1408px 576px #fff,
    1806px 1577px #fff, 662px 1792px #fff, 200px 1495px #fff, 1540px 572px #fff,
    87px 312px #fff, 240px 908px #fff, 660px 1589px #fff, 1431px 597px #fff,
    1560px 715px #fff, 1581px 1894px #fff, 1692px 441px #fff, 1003px 702px #fff,
    897px 1797px #fff, 263px 1583px #fff, 1974px 962px #fff, 1830px 951px #fff,
    203px 563px #fff, 1323px 1457px #fff, 503px 660px #fff, 96px 1711px #fff,
    1028px 1874px #fff, 1378px 1908px #fff, 1072px 1545px #fff,
    1565px 761px #fff, 1728px 1043px #fff, 493px 838px #fff, 693px 1268px #fff,
    565px 1576px #fff, 742px 372px #fff, 1685px 961px #fff, 1883px 1384px #fff,
    702px 641px #fff, 759px 12px #fff, 1782px 42px #fff, 13px 1616px #fff,
    875px 877px #fff, 1045px 1448px #fff, 431px 1347px #fff, 1042px 1952px #fff,
    894px 1257px #fff, 208px 1075px #fff, 2px 102px #fff, 1884px 464px #fff,
    1237px 1298px #fff, 1381px 1238px #fff, 1508px 1090px #fff,
    1411px 123px #fff, 1025px 1315px #fff, 1701px 1783px #fff,
    1720px 1979px #fff, 1304px 1950px #fff, 952px 577px #fff, 549px 799px #fff,
    991px 349px #fff, 1352px 759px #fff, 842px 247px #fff, 827px 1173px #fff,
    95px 465px #fff, 1930px 1523px #fff, 480px 250px #fff, 1678px 1811px #fff,
    1882px 1230px #fff, 10px 1792px #fff, 1841px 915px #fff, 1542px 1528px #fff,
    265px 821px #fff, 645px 853px #fff, 1970px 1514px #fff, 1936px 1728px #fff,
    846px 1425px #fff, 166px 1848px #fff, 275px 300px #fff, 1420px 1609px #fff,
    320px 546px #fff, 1162px 1979px #fff, 1445px 1350px #fff, 979px 42px #fff,
    1984px 1977px #fff, 1693px 815px #fff, 694px 416px #fff, 1276px 1142px #fff,
    1672px 1579px #fff, 1464px 1454px #fff, 789px 1422px #fff, 226px 659px #fff,
    1665px 1068px #fff, 9px 1574px #fff, 546px 863px #fff, 1804px 1757px #fff,
    1725px 891px #fff, 247px 1845px #fff, 86px 1646px #fff, 970px 1896px #fff,
    900px 447px #fff, 1587px 915px #fff, 956px 251px #fff, 191px 1618px #fff,
    102px 288px #fff, 575px 1020px #fff, 1363px 1474px #fff, 232px 171px #fff,
    140px 553px #fff, 1421px 610px #fff, 1452px 137px #fff, 1634px 1756px #fff,
    1158px 1600px #fff, 1531px 1225px #fff, 237px 1285px #fff,
    1555px 1317px #fff, 683px 236px #fff, 1358px 810px #fff, 178px 1162px #fff,
    380px 1178px #fff, 1847px 1596px #fff, 216px 149px #fff, 504px 112px #fff,
    1232px 1094px #fff, 136px 598px #fff, 1679px 562px #fff, 289px 921px #fff,
    1731px 570px #fff, 362px 1432px #fff, 506px 1642px #fff, 1671px 642px #fff,
    1007px 1243px #fff, 203px 416px #fff, 803px 896px #fff, 1973px 1819px #fff,
    612px 198px #fff, 863px 1864px #fff, 975px 673px #fff, 116px 948px #fff,
    1635px 1921px #fff, 1031px 115px #fff, 1616px 1525px #fff, 500px 519px #fff,
    549px 425px #fff, 1746px 1505px #fff, 1669px 1058px #fff, 891px 1953px #fff,
    946px 647px #fff, 1021px 432px #fff, 991px 841px #fff, 383px 1773px #fff,
    1947px 1249px #fff, 1526px 1055px #fff, 1072px 973px #fff, 751px 1066px #fff,
    816px 1289px #fff, 1362px 956px #fff, 889px 629px #fff, 390px 89px #fff,
    1523px 725px #fff, 423px 43px #fff, 794px 81px #fff, 706px 1552px #fff,
    637px 245px #fff, 1847px 43px #fff, 965px 1249px #fff, 1653px 1496px #fff,
    1330px 1901px #fff, 638px 824px #fff, 891px 612px #fff, 217px 677px #fff,
    145px 1272px #fff, 1553px 834px #fff, 1058px 1959px #fff, 1062px 995px #fff,
    420px 531px #fff, 1463px 666px #fff, 1975px 1971px #fff, 1434px 872px #fff,
    110px 1445px #fff, 1917px 1659px #fff, 1462px 1427px #fff, 10px 322px #fff,
    1935px 557px #fff, 1484px 516px #fff, 632px 1593px #fff, 1085px 666px #fff,
    1003px 1039px #fff, 1432px 1377px #fff, 1703px 88px #fff, 511px 725px #fff,
    481px 1738px #fff, 1831px 1px #fff, 619px 1825px #fff, 212px 1208px #fff,
    1154px 67px #fff, 1881px 1345px #fff, 1852px 744px #fff, 1563px 945px #fff,
    766px 1746px #fff, 209px 1601px #fff, 990px 1959px #fff, 1296px 707px #fff,
    1888px 939px #fff, 327px 1447px #fff, 194px 1689px #fff, 1289px 1906px #fff,
    305px 887px #fff, 1430px 355px #fff, 1450px 1563px #fff, 279px 1232px #fff,
    322px 457px #fff, 1508px 908px #fff, 1169px 1977px #fff, 248px 1157px #fff,
    73px 1030px #fff, 868px 1109px #fff, 1787px 1231px #fff, 534px 1867px #fff,
    1756px 1068px #fff, 121px 1917px #fff, 191px 633px #fff, 295px 200px #fff,
    750px 882px #fff, 1133px 807px #fff, 562px 548px #fff, 634px 579px #fff,
    1413px 1453px #fff, 109px 147px #fff, 1858px 44px #fff, 1145px 94px #fff,
    247px 693px #fff, 147px 100px #fff, 823px 372px #fff, 1742px 457px #fff,
    165px 926px #fff, 5px 1810px #fff, 1783px 345px #fff, 74px 1390px #fff,
    778px 1419px #fff, 846px 297px #fff, 1918px 1360px #fff, 166px 249px #fff,
    1962px 796px #fff, 1159px 82px #fff, 975px 1765px #fff, 1481px 470px #fff,
    135px 1723px #fff, 1319px 82px #fff, 1833px 1228px #fff, 1997px 619px #fff,
    1905px 1442px #fff, 1702px 1228px #fff, 1769px 1474px #fff,
    1334px 327px #fff, 1961px 364px #fff, 667px 580px #fff, 8px 386px #fff,
    192px 879px #fff, 1277px 288px #fff, 734px 4px #fff, 429px 862px #fff,
    1494px 853px #fff, 435px 894px #fff, 1621px 1436px #fff, 899px 1480px #fff,
    963px 699px #fff, 572px 1975px #fff, 1145px 1412px #fff, 225px 1161px #fff,
    1016px 402px #fff, 1621px 616px #fff, 33px 438px #fff, 66px 488px #fff,
    1847px 515px #fff, 1869px 1752px #fff, 1943px 1990px #fff,
    1136px 1445px #fff, 1097px 1868px #fff, 1035px 655px #fff, 1170px 466px #fff,
    1512px 1253px #fff, 732px 963px #fff, 1059px 371px #fff, 1749px 930px #fff,
    1086px 1514px #fff, 4px 1250px #fff, 1559px 1420px #fff, 1649px 616px #fff,
    551px 1438px #fff, 471px 1326px #fff, 211px 1307px #fff, 558px 330px #fff,
    440px 571px #fff, 891px 959px #fff, 1458px 1604px #fff, 1852px 145px #fff,
    1832px 446px #fff, 1945px 786px #fff, 1956px 1405px #fff, 670px 682px #fff,
    936px 1496px #fff, 1163px 739px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;

  box-shadow: 903px 338px #fff, 205px 36px #fff, 268px 125px #fff,
    1060px 909px #fff, 1635px 853px #fff, 356px 1804px #fff, 768px 73px #fff,
    1823px 1763px #fff, 753px 1309px #fff, 213px 633px #fff, 1815px 1573px #fff,
    574px 1653px #fff, 736px 161px #fff, 303px 1035px #fff, 1979px 250px #fff,
    187px 474px #fff, 1815px 655px #fff, 125px 193px #fff, 478px 1896px #fff,
    104px 123px #fff, 1187px 1634px #fff, 238px 259px #fff, 846px 1711px #fff,
    599px 514px #fff, 990px 875px #fff, 1558px 267px #fff, 1542px 981px #fff,
    334px 310px #fff, 683px 1079px #fff, 1255px 1891px #fff, 534px 966px #fff,
    610px 283px #fff, 1924px 1427px #fff, 269px 4px #fff, 629px 1384px #fff,
    1411px 1988px #fff, 1981px 86px #fff, 1927px 809px #fff, 1767px 244px #fff,
    494px 1334px #fff, 1793px 613px #fff, 1444px 830px #fff, 84px 182px #fff,
    1950px 212px #fff, 796px 749px #fff, 1948px 61px #fff, 1747px 1504px #fff,
    1316px 260px #fff, 737px 825px #fff, 456px 582px #fff, 1605px 1355px #fff,
    364px 1452px #fff, 1553px 1871px #fff, 1076px 1173px #fff,
    1614px 1058px #fff, 681px 1692px #fff, 952px 871px #fff, 1048px 139px #fff,
    408px 990px #fff, 345px 516px #fff, 1416px 1500px #fff, 1382px 1961px #fff,
    258px 1248px #fff, 380px 1634px #fff, 1935px 1552px #fff, 995px 636px #fff,
    338px 1551px #fff, 1189px 1863px #fff, 1860px 770px #fff, 1969px 66px #fff,
    228px 1258px #fff, 839px 649px #fff, 1432px 1632px #fff, 1846px 865px #fff,
    1900px 694px #fff, 903px 487px #fff, 776px 518px #fff, 250px 836px #fff,
    1168px 782px #fff, 1895px 177px #fff, 219px 1015px #fff, 937px 706px #fff,
    1001px 478px #fff, 1262px 812px #fff, 517px 1472px #fff, 375px 870px #fff,
    600px 396px #fff, 32px 1202px #fff, 1612px 613px #fff, 1846px 93px #fff,
    1101px 1440px #fff, 1150px 502px #fff, 1618px 697px #fff, 485px 70px #fff,
    575px 1165px #fff, 525px 1616px #fff, 1572px 1133px #fff, 267px 940px #fff,
    1445px 907px #fff, 1188px 1988px #fff, 344px 1454px #fff, 832px 1601px #fff,
    239px 716px #fff, 787px 1963px #fff, 1871px 840px #fff, 193px 927px #fff,
    1921px 344px #fff, 854px 1202px #fff, 1963px 290px #fff, 1875px 1220px #fff,
    1086px 1415px #fff, 451px 542px #fff, 1495px 1492px #fff, 1857px 868px #fff,
    1577px 1344px #fff, 1546px 1818px #fff, 929px 867px #fff, 744px 1209px #fff,
    816px 839px #fff, 276px 1917px #fff, 679px 202px #fff, 687px 1897px #fff,
    922px 1589px #fff, 10px 23px #fff, 1006px 1147px #fff, 1200px 1228px #fff,
    1086px 665px #fff, 888px 357px #fff, 1920px 1844px #fff, 992px 1054px #fff,
    798px 385px #fff, 644px 1216px #fff, 2000px 1597px #fff, 831px 1657px #fff,
    1522px 225px #fff, 1373px 536px #fff, 1724px 1231px #fff, 891px 858px #fff,
    1315px 1970px #fff, 387px 1393px #fff, 1592px 472px #fff, 897px 1899px #fff,
    883px 1637px #fff, 313px 964px #fff, 1865px 445px #fff, 1232px 1533px #fff,
    85px 205px #fff, 569px 48px #fff, 1155px 1779px #fff, 144px 1353px #fff,
    93px 1559px #fff, 465px 948px #fff, 234px 1733px #fff, 1836px 490px #fff,
    1814px 978px #fff, 182px 316px #fff, 862px 184px #fff, 1089px 1636px #fff,
    1915px 1204px #fff, 305px 32px #fff, 1523px 3px #fff, 1446px 1718px #fff,
    1650px 1891px #fff, 991px 1162px #fff, 1734px 391px #fff, 60px 196px #fff,
    1495px 1391px #fff, 973px 989px #fff, 620px 1273px #fff, 616px 1445px #fff,
    855px 1017px #fff, 1835px 488px #fff, 338px 1986px #fff, 1368px 777px #fff,
    1299px 1317px #fff, 1691px 434px #fff, 342px 1058px #fff, 312px 473px #fff,
    1077px 580px #fff, 278px 803px #fff, 286px 1102px #fff, 176px 1825px #fff,
    1050px 232px #fff, 1953px 1924px #fff, 537px 831px #fff, 1129px 164px #fff,
    728px 1146px #fff, 863px 705px #fff, 1742px 1794px #fff, 1345px 1595px #fff,
    1024px 67px #fff, 1613px 1308px #fff, 329px 1230px #fff, 1295px 569px #fff,
    949px 753px #fff, 1256px 1813px #fff, 678px 1663px #fff, 1964px 1497px #fff,
    1401px 149px #fff, 1274px 761px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;

  box-shadow: 903px 338px #fff, 205px 36px #fff, 268px 125px #fff,
    1060px 909px #fff, 1635px 853px #fff, 356px 1804px #fff, 768px 73px #fff,
    1823px 1763px #fff, 753px 1309px #fff, 213px 633px #fff, 1815px 1573px #fff,
    574px 1653px #fff, 736px 161px #fff, 303px 1035px #fff, 1979px 250px #fff,
    187px 474px #fff, 1815px 655px #fff, 125px 193px #fff, 478px 1896px #fff,
    104px 123px #fff, 1187px 1634px #fff, 238px 259px #fff, 846px 1711px #fff,
    599px 514px #fff, 990px 875px #fff, 1558px 267px #fff, 1542px 981px #fff,
    334px 310px #fff, 683px 1079px #fff, 1255px 1891px #fff, 534px 966px #fff,
    610px 283px #fff, 1924px 1427px #fff, 269px 4px #fff, 629px 1384px #fff,
    1411px 1988px #fff, 1981px 86px #fff, 1927px 809px #fff, 1767px 244px #fff,
    494px 1334px #fff, 1793px 613px #fff, 1444px 830px #fff, 84px 182px #fff,
    1950px 212px #fff, 796px 749px #fff, 1948px 61px #fff, 1747px 1504px #fff,
    1316px 260px #fff, 737px 825px #fff, 456px 582px #fff, 1605px 1355px #fff,
    364px 1452px #fff, 1553px 1871px #fff, 1076px 1173px #fff,
    1614px 1058px #fff, 681px 1692px #fff, 952px 871px #fff, 1048px 139px #fff,
    408px 990px #fff, 345px 516px #fff, 1416px 1500px #fff, 1382px 1961px #fff,
    258px 1248px #fff, 380px 1634px #fff, 1935px 1552px #fff, 995px 636px #fff,
    338px 1551px #fff, 1189px 1863px #fff, 1860px 770px #fff, 1969px 66px #fff,
    228px 1258px #fff, 839px 649px #fff, 1432px 1632px #fff, 1846px 865px #fff,
    1900px 694px #fff, 903px 487px #fff, 776px 518px #fff, 250px 836px #fff,
    1168px 782px #fff, 1895px 177px #fff, 219px 1015px #fff, 937px 706px #fff,
    1001px 478px #fff, 1262px 812px #fff, 517px 1472px #fff, 375px 870px #fff,
    600px 396px #fff, 32px 1202px #fff, 1612px 613px #fff, 1846px 93px #fff,
    1101px 1440px #fff, 1150px 502px #fff, 1618px 697px #fff, 485px 70px #fff,
    575px 1165px #fff, 525px 1616px #fff, 1572px 1133px #fff, 267px 940px #fff,
    1445px 907px #fff, 1188px 1988px #fff, 344px 1454px #fff, 832px 1601px #fff,
    239px 716px #fff, 787px 1963px #fff, 1871px 840px #fff, 193px 927px #fff,
    1921px 344px #fff, 854px 1202px #fff, 1963px 290px #fff, 1875px 1220px #fff,
    1086px 1415px #fff, 451px 542px #fff, 1495px 1492px #fff, 1857px 868px #fff,
    1577px 1344px #fff, 1546px 1818px #fff, 929px 867px #fff, 744px 1209px #fff,
    816px 839px #fff, 276px 1917px #fff, 679px 202px #fff, 687px 1897px #fff,
    922px 1589px #fff, 10px 23px #fff, 1006px 1147px #fff, 1200px 1228px #fff,
    1086px 665px #fff, 888px 357px #fff, 1920px 1844px #fff, 992px 1054px #fff,
    798px 385px #fff, 644px 1216px #fff, 2000px 1597px #fff, 831px 1657px #fff,
    1522px 225px #fff, 1373px 536px #fff, 1724px 1231px #fff, 891px 858px #fff,
    1315px 1970px #fff, 387px 1393px #fff, 1592px 472px #fff, 897px 1899px #fff,
    883px 1637px #fff, 313px 964px #fff, 1865px 445px #fff, 1232px 1533px #fff,
    85px 205px #fff, 569px 48px #fff, 1155px 1779px #fff, 144px 1353px #fff,
    93px 1559px #fff, 465px 948px #fff, 234px 1733px #fff, 1836px 490px #fff,
    1814px 978px #fff, 182px 316px #fff, 862px 184px #fff, 1089px 1636px #fff,
    1915px 1204px #fff, 305px 32px #fff, 1523px 3px #fff, 1446px 1718px #fff,
    1650px 1891px #fff, 991px 1162px #fff, 1734px 391px #fff, 60px 196px #fff,
    1495px 1391px #fff, 973px 989px #fff, 620px 1273px #fff, 616px 1445px #fff,
    855px 1017px #fff, 1835px 488px #fff, 338px 1986px #fff, 1368px 777px #fff,
    1299px 1317px #fff, 1691px 434px #fff, 342px 1058px #fff, 312px 473px #fff,
    1077px 580px #fff, 278px 803px #fff, 286px 1102px #fff, 176px 1825px #fff,
    1050px 232px #fff, 1953px 1924px #fff, 537px 831px #fff, 1129px 164px #fff,
    728px 1146px #fff, 863px 705px #fff, 1742px 1794px #fff, 1345px 1595px #fff,
    1024px 67px #fff, 1613px 1308px #fff, 329px 1230px #fff, 1295px 569px #fff,
    949px 753px #fff, 1256px 1813px #fff, 678px 1663px #fff, 1964px 1497px #fff,
    1401px 149px #fff, 1274px 761px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;

  box-shadow: 220px 761px #fff, 1859px 1678px #fff, 245px 349px #fff,
    1873px 1050px #fff, 596px 1177px #fff, 1764px 345px #fff, 645px 1811px #fff,
    149px 1715px #fff, 410px 1144px #fff, 366px 1177px #fff, 1675px 1294px #fff,
    1051px 629px #fff, 713px 1551px #fff, 519px 112px #fff, 359px 155px #fff,
    463px 270px #fff, 1079px 1168px #fff, 1818px 1182px #fff, 82px 733px #fff,
    1411px 196px #fff, 50px 938px #fff, 1032px 1698px #fff, 752px 1989px #fff,
    1809px 941px #fff, 1289px 1472px #fff, 808px 48px #fff, 1966px 396px #fff,
    1465px 550px #fff, 305px 1720px #fff, 1593px 1172px #fff, 1955px 1429px #fff,
    1156px 477px #fff, 871px 723px #fff, 1819px 1935px #fff, 175px 939px #fff,
    724px 1789px #fff, 156px 1810px #fff, 1257px 1384px #fff, 385px 1433px #fff,
    888px 1766px #fff, 1850px 542px #fff, 1234px 1962px #fff, 646px 1398px #fff,
    1751px 966px #fff, 1701px 196px #fff, 1630px 501px #fff, 1670px 1093px #fff,
    645px 99px #fff, 1059px 618px #fff, 1768px 1961px #fff, 1347px 96px #fff,
    329px 1009px #fff, 1515px 975px #fff, 1094px 270px #fff, 1740px 207px #fff,
    495px 1620px #fff, 1204px 253px #fff, 964px 1305px #fff, 628px 990px #fff,
    227px 1318px #fff, 895px 778px #fff, 469px 1148px #fff, 1272px 1639px #fff,
    96px 1139px #fff, 710px 1457px #fff, 182px 1340px #fff, 259px 1610px #fff,
    1021px 1154px #fff, 984px 1327px #fff, 663px 357px #fff, 1912px 1474px #fff,
    248px 186px #fff, 1693px 522px #fff, 1342px 417px #fff, 1967px 1110px #fff,
    62px 709px #fff, 817px 904px #fff, 158px 326px #fff, 1813px 954px #fff,
    1124px 1104px #fff, 161px 1619px #fff, 1509px 1585px #fff,
    1195px 1655px #fff, 1872px 1399px #fff, 864px 1521px #fff, 512px 232px #fff,
    162px 951px #fff, 585px 393px #fff, 1453px 1584px #fff, 1728px 583px #fff,
    695px 1817px #fff, 392px 1861px #fff, 490px 648px #fff, 1386px 426px #fff,
    182px 1359px #fff, 1298px 1142px #fff, 1515px 312px #fff, 1814px 1582px #fff,
    1394px 1388px #fff, 875px 761px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;

  box-shadow: 220px 761px #fff, 1859px 1678px #fff, 245px 349px #fff,
    1873px 1050px #fff, 596px 1177px #fff, 1764px 345px #fff, 645px 1811px #fff,
    149px 1715px #fff, 410px 1144px #fff, 366px 1177px #fff, 1675px 1294px #fff,
    1051px 629px #fff, 713px 1551px #fff, 519px 112px #fff, 359px 155px #fff,
    463px 270px #fff, 1079px 1168px #fff, 1818px 1182px #fff, 82px 733px #fff,
    1411px 196px #fff, 50px 938px #fff, 1032px 1698px #fff, 752px 1989px #fff,
    1809px 941px #fff, 1289px 1472px #fff, 808px 48px #fff, 1966px 396px #fff,
    1465px 550px #fff, 305px 1720px #fff, 1593px 1172px #fff, 1955px 1429px #fff,
    1156px 477px #fff, 871px 723px #fff, 1819px 1935px #fff, 175px 939px #fff,
    724px 1789px #fff, 156px 1810px #fff, 1257px 1384px #fff, 385px 1433px #fff,
    888px 1766px #fff, 1850px 542px #fff, 1234px 1962px #fff, 646px 1398px #fff,
    1751px 966px #fff, 1701px 196px #fff, 1630px 501px #fff, 1670px 1093px #fff,
    645px 99px #fff, 1059px 618px #fff, 1768px 1961px #fff, 1347px 96px #fff,
    329px 1009px #fff, 1515px 975px #fff, 1094px 270px #fff, 1740px 207px #fff,
    495px 1620px #fff, 1204px 253px #fff, 964px 1305px #fff, 628px 990px #fff,
    227px 1318px #fff, 895px 778px #fff, 469px 1148px #fff, 1272px 1639px #fff,
    96px 1139px #fff, 710px 1457px #fff, 182px 1340px #fff, 259px 1610px #fff,
    1021px 1154px #fff, 984px 1327px #fff, 663px 357px #fff, 1912px 1474px #fff,
    248px 186px #fff, 1693px 522px #fff, 1342px 417px #fff, 1967px 1110px #fff,
    62px 709px #fff, 817px 904px #fff, 158px 326px #fff, 1813px 954px #fff,
    1124px 1104px #fff, 161px 1619px #fff, 1509px 1585px #fff,
    1195px 1655px #fff, 1872px 1399px #fff, 864px 1521px #fff, 512px 232px #fff,
    162px 951px #fff, 585px 393px #fff, 1453px 1584px #fff, 1728px 583px #fff,
    695px 1817px #fff, 392px 1861px #fff, 490px 648px #fff, 1386px 426px #fff,
    182px 1359px #fff, 1298px 1142px #fff, 1515px 312px #fff, 1814px 1582px #fff,
    1394px 1388px #fff, 875px 761px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
