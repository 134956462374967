.home-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.home-header-text {
  margin-bottom: 2rem;
  color: white;
  text-align: center;
  font-size: 5rem;
  span {
    color: #037fff;
  }
}

.home-header-subtext {
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.home-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  .home-icon-container {
    margin-right: 1rem;
    .icon {
      font-size: 3rem;
      color: #a4acc4;
      transition: all 0.4s ease-in-out;
    }
    .linkedin:hover {
      color: rgb(73, 165, 186);
    }
    .twitter:hover {
      color: rgb(73, 165, 186);
    }

    .medium:hover {
      color: #c39b77;
    }
    .github:hover {
      color: orchid;
    }
    .email:hover {
      color: red;
    }
  }
}
