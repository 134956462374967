.title-container {
  margin-bottom: 4rem;

  .title-content {
    text-transform: uppercase;
    font-size: 3.5rem;
    color: white;
    position: relative;
    padding-bottom: 1rem;
    letter-spacing: 2px;
    padding-top: 2rem;
    &::before {
      content: "";
      bottom: 0;
      position: absolute;
      left: 0;
      background-color: #04fcdb;
      width: 6rem;
      height: 0.4rem;
      border-radius: 50px;
    }
    &::after {
      content: "";
      bottom: 0;
      position: absolute;
      left: 0;
      background-color: #037fff;
      width: 3rem;
      height: 0.4rem;
      border-radius: 50px;
    }
    span {
      position: absolute;
      top: 15%;
      left: 0;
      font-size: 5rem;
      opacity: 0.07;
    }
  }
}
