.categories-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 10rem;
  flex-wrap: wrap;
}

.category-button {
  padding: 0.6rem 2rem;
  background-color: #0381ff5b;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  color: white;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  &:not(:last-child) {
    margin-right: 1rem;
  }
  &:hover {
    background-color: #037fff;
  }
  &:active {
    background-color: #037fff;
  }
  &:focus {
    background-color: #037fff;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 10rem;
  flex-wrap: wrap;
  @media screen and (max-width: 1270px) {
    margin: 2rem 5rem;
  }
  @media screen and (max-width: 1100px) {
    margin: 2rem 2rem;
  }
}
