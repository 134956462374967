.projects-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5rem;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }
  .project {
    background-color: #161925;
    width: 100%;
    padding: 1rem;
    overflow: hidden;
    .project-content {
      overflow: hidden;
      padding-bottom: 2rem;
      img {
        width: 100%;
        height: 350px;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
        padding-bottom: 2rem;
      }

      .img-container {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 12px;
          left: 15px;
          height: calc(100% - 30px);
          width: calc(100% - 30px);
          background-color: #037fff;
          opacity: 0.95;
          transform-origin: center;
          transform: scale(0);
          transition: all 0.4s ease-in-out;
        }
        &:hover::before {
          transform: scale(1);
        }

        .hover-items {
          list-style: none;
          position: absolute;
          top: 70%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 1rem 2rem;
          visibility: hidden;
          li {
            a {
              padding: 1rem;
              text-decoration: none;
              font-family: inherit;
              border-radius: 10px;
              background-color: white;
              color: #037fff;
              &:not(:last-child) {
                margin-right: 2rem;
              }
            }
          }
        }

        &:hover {
          .hover-items {
            transition: all 0.5s ease-in-out 0.2s;
            visibility: visible;
            top: 50%;
          }
        }
      }

      h5 {
        font-size: 1.7rem;
        font-weight: 400;
        color: white;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: #037fff;
          cursor: pointer;
        }
      }

      .project-link {
        color: inherit;
        font-family: inherit;
        text-decoration: none;
        font-size: 2rem;
        padding-bottom: 2rem;
        transition: all 0.4s ease-in-out;
        &:hover {
          color: #037fff;
        }
      }
    }
  }
}

.project-title {
  margin-bottom: 4rem;
}
