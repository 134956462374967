.ResumePage {
  overflow-x: hidden;

  .resume-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 60vw;
  }
  .resume {
    height: 100%;
    width: 100%;
  }
}
